import * as React from 'react';
import { ReactNode } from 'react';
import ISorter from '../../models/ISorter';
import { Direction } from '../sorting';

interface ISortableBaseState {
    activeFilterValue: string;
}

export interface ISortableBaseProps<T> extends SortableBaseDefaultProps<T> {
    dataSource: T[];
}

export type SortableBaseDefaultProps<T> = {
    sorters: Array<ISorter<T>>;
}

type PropsWithChildrenFunction<P, T> = P & {
    children?(item: T): ReactNode;
}

export abstract class SortableBase<T> extends React.Component<PropsWithChildrenFunction<ISortableBaseProps<T>, T>, ISortableBaseState> {

    constructor(props: ISortableBaseProps<T>) {
        super(props);
        this.state = {
            activeFilterValue: this.lastSelectedValue
        }
        this.sortFunc = this.sortFunc.bind(this);
    }

    private getStorageKey(): string {
        return `imitate_${this.constructor.name}`;
    }

    get lastSelectedValue(): string {
        return window.localStorage.getItem(this.getStorageKey()) ?? "";
    }

    set lastSelectedValue(value: string) {
        window.localStorage.setItem(this.getStorageKey(), value);
    }

    private sortFunc(a: T, b: T): number {
        const filteredSorters = this.props.sorters.filter(sorter => `${sorter.property}_${sorter.direction}` === this.state.activeFilterValue);
        if (filteredSorters.length === 0) {
            return 0;
        }
        const filteredSorter = filteredSorters[0];
        const property = filteredSorter.property;

        // typescript was smart enough to understand this line... incredible
        if (a[property] > b[property]) {
            return filteredSorter.direction === Direction.Asc ? 1 : -1;
        } else if (a[property] < b[property]) {
            return filteredSorter.direction === Direction.Asc ? -1 : 1;
        }
        return 0;
    }

    public render() {
        const { dataSource, children } = this.props;
        return (
            <>
                <div className="row justify-content-center">
                    <div className="col-md-6 col-sm-12 p-2">
                        <select className="form-control form-control-sm" value={this.state.activeFilterValue} onChange={(e) => {
                                const value = e.target.value;
                                this.setState({
                                    activeFilterValue: value
                                });
                                this.lastSelectedValue = value;
                            }
                        }>
                            <option disabled={true} value="">bilder sortieren...</option>
                            {this.props.sorters.map(sorter => {
                                const key = `${sorter.property}_${sorter.direction}`;
                                return (
                                    <option key={key} value={key}>{sorter.label}</option>
                                )
                            })}
                        </select>
                    </div>
                </div>
                {children && <div className="row">
                    {dataSource
                        .sort(this.sortFunc)
                        .map((x, i) => children(x))
                    }
                </div>}
            </>
        );
    }
}


