import * as React from "react";
import { useEffect, useState } from "react";
import { ImageSize, ImitationPreviewModel, } from "../../models";
import FieldEditor from "../Editor/FieldEditor";
import { FieldEditorType } from "../Editor/FieldEditorType";
import Title from "../Shared/Title";
import Loader from "../Infrastructure/Loader";
import { useParams } from "react-router-dom";
import IRouterParams from "../Router/IRouterParams";
import { ApiService } from "../../services";
import { ToastHelpers } from "../../helpers/ToastHelpers";
import useWindowScrollPosition from "../../hooks/useWindowScrollPosition";

export default function MassEdit() {
    const { collectionId } = useParams<IRouterParams>();
    const [imitations, setImitations] = useState<ImitationPreviewModel[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    useWindowScrollPosition('Imitation_MassEdit_ScrollY', !isLoading);

    const fetchData = async () => {
        try {
            if (imitations.length <= 0) {
                const apiService = new ApiService(`${collectionId}/imitation`);
                const imitations = await apiService.get<ImitationPreviewModel[]>();
                setImitations(imitations);
            }
        } catch (error) {
            ToastHelpers.showSimple('🙅 album kann nicht geöffnet werden.');
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (isLoading) {
            fetchData();
        }
    });

    if (isLoading) {
        return <Loader />;
    }

    return (
        <>
            <Title title="album bearbeiten" urlText="zur ansicht wechseln" url={`/collection/${collectionId}`} />
            <div className="container">
                <div className="row">
                    {imitations.map((x) => {
                        return (
                            <React.Fragment key={`ie_${x.id}`}>
                                <div className="col-sm-2 mb-2">
                                    <img className="w-100" src={x.image.sources[ImageSize.Square]} alt="Preview" />
                                </div>
                                <div className="col-sm-4">
                                    <FieldEditor
                                        id={x.image.id}
                                        value={x.image.title}
                                        type={FieldEditorType.Input}
                                        label="titel:"
                                        propertyName="Title"
                                        endpoint={`/${x.id}/image/${x.image.id}`}
                                    />
                                    <FieldEditor
                                        id={x.image.id}
                                        value={x.image.comments}
                                        type={FieldEditorType.Textarea}
                                        label="kommentare:"
                                        propertyName="Comments"
                                        endpoint={`/${x.id}/image/${x.image.id}`}
                                    />
                                </div>
                            </React.Fragment>
                        );
                    })}
                </div>
            </div>
        </>
    );
}
