import * as React from 'react';
import { ImageSize, ImitationViewModel } from '../../models';
import FieldEditor from '../Editor/FieldEditor';
import { FieldEditorType } from '../Editor/FieldEditorType';
import { useParams } from 'react-router-dom';
import IRouterParams from '../Router/IRouterParams';
import { useEffect } from 'react';
import Loader from '../Infrastructure/Loader';
import Title from '../Shared/Title';
import { ApiService } from '../../services';
import { ToastHelpers } from '../../helpers/ToastHelpers';
import useWindowScrollPosition from '../../hooks/useWindowScrollPosition';

export default function Edit() {
    const { imitationId, collectionId } = useParams<IRouterParams>();
    const [imitation, setImitation] = React.useState<ImitationViewModel | null>(null);
    const [isLoading, setIsLoading] = React.useState<boolean>(true);
    useWindowScrollPosition('Imitation_Edit_ScrollY', !isLoading);

    useEffect(() => {
        if (imitationId && isLoading) {
            const apiService = new ApiService(`${collectionId}/imitation/${imitationId}`);
            apiService.get<ImitationViewModel>()
                .then(
                    (value) => {
                        setImitation(value);
                        setIsLoading(false);
                    },
                    (reason: any) => {
                        ToastHelpers.showSimple('🖼️ bild kann nicht geladen werden. bitte später nochmals versuchen.');
                        setIsLoading(false);
                    });
        }
    }, [imitationId, collectionId, isLoading]);

    if (!imitation || !imitationId) {
        return <Loader />
    }

    return (
        <>
            <Title title="bildeigenschaften bearbeiten" urlText="zurück zum album" url={`/collection/${collectionId}`} />
            <div className="container">
                <div className="row pb-4">
                    <div className="col-md-6">
                        <img className="w-100" src={imitation.cover.sources[ImageSize.Square]} alt="Preview" />
                    </div>
                    <div className="col-md-6">
                        <FieldEditor
                            id={imitation.cover.id}
                            value={imitation.cover.title}
                            type={FieldEditorType.Input}
                            label="titel:"
                            propertyName="Title"
                            endpoint={`/${imitationId}/image/${imitation.cover.id}`}
                        />
                        <FieldEditor
                            id={imitation.cover.id}
                            value={imitation.cover.comments}
                            type={FieldEditorType.Textarea}
                            label="kommentare:"
                            propertyName="Comments"
                            endpoint={`/${imitationId}/image/${imitation.cover.id}`}
                        />
                    </div>
                </div>
            </div>
        </>
    );
}
