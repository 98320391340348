import * as React from 'react';
import { useParams } from 'react-router-dom';
import { ImagePreviewModel, ImageSize, ImitationViewModel } from '../../models';
import Loader from '../Infrastructure/Loader';
import { useEffect } from 'react';
import { ImageLoader } from '../Image/ImageLoader';
import FileUploader from '../Image/FileUploader';
import IRouterParams from '../Router/IRouterParams';
import DetailUploads from './DetailUploads';
import Title from '../Shared/Title';
import { ApiService } from '../../services';
import { ToastHelpers } from '../../helpers/ToastHelpers';

export default function Detail() {
    const { collectionId, imitationId } = useParams<IRouterParams>();
    const [imitation, setImitation] = React.useState<ImitationViewModel | null>(null);
    const [images, setImages] = React.useState<Array<ImagePreviewModel>>([]);
    const [isLoading, setIsLoading] = React.useState<boolean>(true);

    const fetchImages = React.useCallback(() => {
        const apiService = new ApiService(`${imitationId}/image`);
        apiService.get<Array<ImagePreviewModel>>()
            .then(
                (value) => {
                    setImages(value);
                    setIsLoading(false);
                },
                (reason: any) => {
                    ToastHelpers.showSimple('🙀 nachstellungen von anderen personen können nicht geladen werden. versuchs nochmals.');
                    setIsLoading(false);
                });
    }, [imitationId])

    const fetchImitation = React.useCallback(() => {
        const apiService = new ApiService(`${collectionId}/imitation/${imitationId}`);
        apiService.get<ImitationViewModel>()
            .then(
                (value) => {
                    setImitation(value);
                    fetchImages();
                },
                (reason: any) => {
                    ToastHelpers.showSimple('🙀 imitation kann nicht geladen werden. versuchs nochmals.');
                    setIsLoading(false);
                });
    }, [collectionId, imitationId, fetchImages])

    useEffect(() => {
        if (imitationId && isLoading) {
            fetchImitation();
        }
    }, [fetchImitation, imitationId, isLoading]);

    if (!imitation || !imitationId || collectionId === imitation.nextId || collectionId === imitation.prevId) {
        return <Loader />
    }

    return (
        <>
            <Title title={imitation.cover.title}
                urlText="zurück zum album"
                url={`/collection/${collectionId}`}
                nextUrl={`/collection/${collectionId}/imitation/${imitation.nextId}`}
                prevUrl={`/collection/${collectionId}/imitation/${imitation.prevId}`} />

            <div className="container">
                <div className="row">
                    <div className="col-md-6 p-2 large">
                        <ImageLoader image={imitation.cover} size={ImageSize.Full} />
                    </div>
                    <div className="col-md-6 p-2">
                        <FileUploader imitationId={imitationId} collectionId={collectionId} onImageChange={fetchImages} />
                    </div>
                </div>
                <DetailUploads isLoading={isLoading} images={images} collectionId={collectionId} imitationId={imitationId} />
            </div>
        </>

    );
}
