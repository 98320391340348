import * as React from "react";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { ToastHelpers } from "../../helpers/ToastHelpers";
import useWindowScrollPosition from "../../hooks/useWindowScrollPosition";
import { ImitationPreviewModel } from "../../models";
import { ApiService } from "../../services/ApiService";
import { SortableImitations } from "../../services/new-sorting/SortableImitations";
import Loader from "../Infrastructure/Loader";
import IRouterParams from "../Router/IRouterParams";
import Preview from "../Shared/Preview";
import Title from "../Shared/Title";

export default function Detail() {

    const { collectionId } = useParams<IRouterParams>();
    const [imitations, setImitations] = useState<ImitationPreviewModel[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    useWindowScrollPosition('Collection_Detail_ScrollY', !isLoading);

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (imitations.length <= 0) {
                    const apiService = new ApiService(`${collectionId}/imitation`, `/collection/${collectionId}`);
                    const imitations = await apiService.get<ImitationPreviewModel[]>();
                    setImitations(imitations);
                }
            } catch (error) {
                ToastHelpers.showSimple('🙅 album kann nicht geöffnet werden.');
            } finally {
                setIsLoading(false);
            }
        }
        if (isLoading) {
            fetchData();
        }
    },[isLoading, collectionId, imitations.length]);

    if (isLoading) {
        return <Loader />;
    }

    const onClickShareUrl = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();
        const shareUrl = `${window.location.protocol}//${window.location.host}/collection/${collectionId}`;
        navigator.clipboard.writeText(shareUrl);
        ToastHelpers.showSimple('😻 kopiert. jetzt musst du es nur noch teilen.');
    };

    return (
        <>
            <Title title="wähle ein bild und stelle es nach" urlText="alle öffentlichen alben anschauen" url={`/`} />
            <div className="container">
                <div className="row justify-content-center p-2">
                    <div className="alert alert-warning text-center" style={{ marginTop: 0 }}>
                        eine erklärung in videoform findet ihr <a href="https://youtu.be/imgfCUrx-ok" title="YouTube öffnen" target="youtube">hier</a>.
                    </div>
                </div>
                {imitations.length > 0 && (
                    <>
                        <SortableImitations dataSource={imitations}>
                                {(x: ImitationPreviewModel) => (
                                    <Preview {...x}
                                        imitationId={x.id}
                                        key={`cp_${x.id}`}
                                        className="col-sm-12 col-md-4 p-2"
                                        pixelate={false}
                                        url={`/collection/${collectionId}/imitation/${x.id}`}
                                        deleteEndpoint={`/${collectionId}/imitation/${x.id}`}>
                                        {x.imageCount > 0 &&
                                            <>
                                                <h4 className="img-info">
                                                    {x.imageCount}&nbsp;<span role="img" aria-label="imitations">🐵</span>
                                                </h4>
                                                <div className="img-stats">
                                                    {x.imageCount}&nbsp;<span role="img" aria-label="imitations">🐵</span>
                                                </div>
                                            </>
                                        }
                                    </Preview>
                                )
                            }
                        </SortableImitations>
                        <div className="row justify-content-center">
                            <div className="alert alert-secondary text-center">
                                nicht genug? jetzt noch <Link to={`${collectionId}/add`}>mehr bilder hinzufügen</Link>,
                                alle bilder <Link to={`${collectionId}/mass-edit`}>bearbeiten</Link> oder
                                dieses <button className="btn btn-link" onClick={onClickShareUrl}>album teilen</button>.
                            </div>
                        </div>
                    </>
                )}

                {imitations.length <= 0 &&
                    <div className="row">
                        <div className="col-sm-12 text-center">
                            <img src="/hey.svg" alt="bist du bereit?" width="40%" />
                            <p className="p-3 text-center">
                                hmm? keine fotos in dieser sammlung gefunden! wir sind
                                bereit für <Link to={`${collectionId}/add`}>deine bilder</Link>.
                            </p>
                        </div>
                    </div>
                }
            </div>
        </>
    );
}
