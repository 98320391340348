import * as React from "react";
import { toast, ToastOptions } from "react-toastify";

export abstract class ToastHelpers {
    
    /**
     * Show a simple toast with default options.
     * @param message The message to show.
     * @param options Options to override.
     */
    public static showSimple(message: string, options?: ToastOptions) {
        toast(message, {
            ...options,
            onOpen: () => ToastHelpers.handleOnOpen(),
            onClose: () => ToastHelpers.handleOnClose(),
        });
    }

    /**
     * Shows a toast with custom confirm and deny buttons. Also will fire "deny function" and close if anywhere on the masking div is clicked.
     * @param messageComponent Markup for the message part.
     * @param confirmButton Markup for the confirm button.
     * @param denyButton Markup for deny button.
     * @param denyFunction Function to execute on deny click. Note that the "deny function" fires on any type of dismiss (swipe, clicking away etc. so it fires in the onClose())
     */
    public static showComplex(
        messageComponent: React.ReactNode,
        confirmButton: React.ReactNode,
        denyButton: React.ReactNode,
        denyFunction: () => void
    ): void {
        toast(
            <>
                {messageComponent}
                {confirmButton}
                {denyButton}
            </>,
            {
                position: "top-center",
                autoClose: false,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false,
                progress: undefined,
                closeButton: false,
                onOpen: () => ToastHelpers.handleOnOpen(),
                onClose: () => ToastHelpers.handleOnClose(denyFunction),
            }
        );
    }

    private static handleOnOpen() {
        const elements = document.getElementsByClassName("Toastify");

        // add active class and event listener
        for (let i = 0; i < elements.length; i++) {

            const item = elements[i];

            item.classList.add("active");
            item.addEventListener("click", ToastHelpers.handleClick, false);
        }
    }

    private static handleOnClose(denyFunction?: () => void) {
        const elements = document.getElementsByClassName("Toastify");

        // fire deny function, remove classes, cleanup event listeners

        for (let i = 0; i < elements.length; i++) {

            const item = elements[i];

            if (denyFunction) {
                denyFunction();
            }

            item.classList.add("closing");
            item.removeEventListener("click", ToastHelpers.handleClick);

            // Close all toasts after each other
            setTimeout(() => {
                item.classList.remove("closing", "active");
            }, i * 50 + 250);
        }
    }

    private static handleClick() {
        toast.dismiss();
    }
}
