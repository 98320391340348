import { ValidationError } from "../models/ValidationError";

export abstract class ErrorHelpers {

    public static getFieldErrors(error: ValidationError, field: string): string[] {
        if (error.errors) {
            const fieldErrors = ErrorHelpers.caseInsensitiveLookup(error.errors, field);
            if (!fieldErrors || fieldErrors.length <= 0) {
                return []
            }

            return fieldErrors;
        }
        
        return ErrorHelpers.caseInsensitiveLookup(error, field);
    }

    private static caseInsensitiveLookup(object: any, key: string) {
        // Because .NET returns the field names with PascalCase, so we need a case insensitive comparison here.
        const keys = Object.keys(object).find(k => k.toLowerCase() === key.toLowerCase());
        if (keys === undefined || keys.length <= 0) {
            return null;
        }

        // In case there is only one match (should always be the case), return the key, otherwise take first element
        return Array.isArray(keys) ? object[keys[0]] : object[keys];
    }
}


