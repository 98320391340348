import * as React from 'react';
import CollectionAdd from '../Collection/Add';
import CollectionDetail from '../Collection/Detail';
import CollectionEdit from '../Collection/Edit';
import ImitationMassEdit from '../Imitation/MassEdit';
import Constants from '../../constants/Constants';
import ImageDetail from '../Image/Detail';
import ImageEdit from '../Image/Edit';
import ImitationAdd from '../Imitation/Add';
import ImitationDetail from '../Imitation/Detail';
import ImitationEdit from '../Imitation/Edit';
import NotFound from '../Infrastructure/NotFound';
import Overview from '../Collection/Overview';
import { Login } from '../Login/Login';
import { Switch, Route } from 'react-router-dom';

export function AppRouter() {
    return (
        <Switch>
            <Route exact path={`/collection/:collectionId(${Constants.GuidRegex})`}>
                <CollectionDetail />
            </Route>
            <Route exact path={`/collection/:collectionId(${Constants.GuidRegex})/edit`}>
                <CollectionEdit />
            </Route>
            <Route exact path={`/collection/:collectionId(${Constants.GuidRegex})/add`}>
                <ImitationAdd />
            </Route>
            <Route exact path={`/collection/:collectionId(${Constants.GuidRegex})/imitation/:imitationId(${Constants.GuidRegex})`}>
                <ImitationDetail />
            </Route>
            <Route exact path={`/collection/:collectionId(${Constants.GuidRegex})/imitation/:imitationId(${Constants.GuidRegex})/edit`}>
                <ImitationEdit />
            </Route>
            <Route exact path={`/collection/:collectionId(${Constants.GuidRegex})/mass-edit`}>
                <ImitationMassEdit />
            </Route>
            <Route exact path={`/collection/:collectionId(${Constants.GuidRegex})/imitation/:imitationId(${Constants.GuidRegex})/image/:imageId(${Constants.GuidRegex})`}>
                <ImageDetail />
            </Route>
            <Route exact path={`/collection/:collectionId(${Constants.GuidRegex})/imitation/:imitationId(${Constants.GuidRegex})/image/:imageId(${Constants.GuidRegex})/edit`}>
                <ImageEdit />
            </Route>
            <Route exact path="/create">
                <CollectionAdd />
            </Route>
            <Route path="/login">
                <Login />
            </Route>
            <Route exact path="/">
                <Overview />
            </Route>
            <Route path="/">
                <NotFound />
            </Route>
        </Switch>
    );
}
