import * as React from "react";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ImageModel, ImageSize } from "../../models";
import { ApiService } from "../../services";
import FieldEditor from "../Editor/FieldEditor";
import { FieldEditorType } from "../Editor/FieldEditorType";
import Loader from "../Infrastructure/Loader";
import IRouterParams from "../Router/IRouterParams";
import Title from "../Shared/Title";

export default function Edit() {
    const { imitationId, imageId, collectionId } = useParams<IRouterParams>();
    const [image, setImage] = useState<ImageModel | undefined>(undefined);

    useEffect(() => {
        if (image === undefined) {
            fetchImage();
        }
    });

    const fetchImage = async () => {
        const apiService = new ApiService(`${imitationId}/image/${imageId}`);
        const image = await apiService.get<ImageModel>();
        setImage(image);
    };

    if (image === undefined) {
        return <Loader />;
    }
    return (
        <>
            <Title title="imitation bearbeiten" urlText="zurück zum originalbild" url={`/collection/${collectionId}/imitation/${imitationId}`} />
            <div className="container">
                <div className="row pb-4">
                    <div className="col-md-6">
                        <img className="w-100 pb-2" src={image.sources[ImageSize.Square]} alt={image.title} />
                    </div>
                    <div className="col-md-6">
                        <FieldEditor
                            id={image.id}
                            value={image.title}
                            type={FieldEditorType.Input}
                            label="titel:"
                            propertyName="Title"
                            endpoint={`/${imitationId}/image/${image.id}`}
                        />
                        <FieldEditor
                            id={image.id}
                            value={image.comments}
                            type={FieldEditorType.Textarea}
                            label="kommentare:"
                            propertyName="Comments"
                            endpoint={`/${imitationId}/image/${image.id}`}
                        />
                    </div>
                </div>
            </div>
        </>
    );
}
