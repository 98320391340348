import { ImitationPreviewModel } from "../../models";
import { Direction } from "../sorting";
import { SortableBase, SortableBaseDefaultProps } from "./SortableBase";

export class SortableImitations extends SortableBase<ImitationPreviewModel> {
    static defaultProps: SortableBaseDefaultProps<ImitationPreviewModel> = {
        sorters: [
            { label: "🧓 älteste", property: "updated", direction: Direction.Asc },
            { label: "👶 neuste", property: "updated", direction: Direction.Desc },
            { label: "🏆 oft nachgestellt", property: "imageCount", direction: Direction.Desc },
            { label: "🐌 wenig nachgestellt", property: "imageCount", direction: Direction.Asc }
        ],
    }
}
