import * as React from "react";
import { ErrorHelpers } from "../../helpers/ErrorHelpers";
import { ToastHelpers } from "../../helpers/ToastHelpers";
import { UploadState } from "../../models";
import UploadedImageFile from "../../models/IUploadedImageFile";
import FieldEditor from "../Editor/FieldEditor";
import { FieldEditorType } from "../Editor/FieldEditorType";

export default function EditInline(props: UploadedImageFile) {
    const { imageId, imitationId, state, error, browserPreviewUrl } = props;

    switch (state) {
        case UploadState.Added:
            return (
                <p className="text-muted p-3">dein bild wird hochgeladen ...</p>
            );
        case UploadState.Error:
            if (error) {
                const fieldErrors = ErrorHelpers.getFieldErrors(error, "Image");
                ToastHelpers.showSimple(`❌ ${fieldErrors.join(", ")}`);
            }
            return <></>;
        case UploadState.Uploaded:
            if (!imageId) {
                return <></>;
            }
            return (
                <>
                    <div className="row pl-3 pr-3 pt-3 pb-1">
                        <div className="col-auto w-25 p-0">
                            <div
                                style={{
                                    backgroundSize: "cover",
                                    backgroundImage:
                                        "url(" + browserPreviewUrl + ")",
                                    width: "100%",
                                    height: "100%",
                                }}
                            ></div>
                        </div>
                        <div className="w-75 pl-2">
                            <FieldEditor
                                id={imageId}
                                value=""
                                type={FieldEditorType.Input}
                                showLabel={false}
                                label="titel"
                                propertyName="Title"
                                endpoint={`/${imitationId}/image/${imageId}`}
                            />
                            <FieldEditor
                                id={imageId}
                                value=""
                                type={FieldEditorType.Textarea}
                                showLabel={false}
                                label="beschreibung"
                                propertyName="Comments"
                                endpoint={`/${imitationId}/image/${imageId}`}
                            />
                        </div>
                    </div>
                </>
            );
        default:
            return <></>;
    }
}
