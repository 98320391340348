import React, { useState } from "react";
import Moment from "react-moment";
import 'moment/locale/de-ch';
import { Link } from "react-router-dom";
import { ImageSize, ImageModel, AccessType, DeletionState, ImageRefreshModel } from "../../models";
import { ImageLoader } from "../Image/ImageLoader";
import { ToastHelpers } from "../../helpers/ToastHelpers";
import { ApiService } from "../../services";
import { RotationState } from "../../models/RotationState";

export interface IPreviewProps {
    className: string;
    updated?: Date;
    title?: string;
    subtitle?: string;
    image?: ImageModel;
    url: string;
    deleteEndpoint?: string;
    rights: AccessType;
    pixelate?: boolean;
    score?: number;
    imitationId?: string;
}

export default function Preview(props: React.PropsWithChildren<IPreviewProps>) {
    const { className, image, url, deleteEndpoint, score, imitationId } = props;
    const [deletionState, setDeletionState] = useState<DeletionState>(DeletionState.Ready);
    const [rotationState, setRotationState] = React.useState<RotationState>(RotationState.Ready);
    const [reloadHash, setReloadHash] = React.useState<string>("");

    const onClickRotate = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        // Not following the actual link anymore as we are rotating
        event.preventDefault();
        if (imitationId && image) {
            const imageRotateService = new ApiService(`${imitationId}/image/${image.id}/rotate`);
            setRotationState(RotationState.Rotating);
            await imageRotateService.post<ImageRefreshModel>({},
                (rotateResponse) => {
                    ToastHelpers.showSimple('👍 bild gedreht');
                    setRotationState(RotationState.Ready);
                    setReloadHash(rotateResponse.hash);
                },
                () => {
                    ToastHelpers.showSimple('🤔 fehler beim drehen. bitte nochmals versuchen.');
                    setRotationState(RotationState.Ready);
                    setReloadHash("");
                }
            )
        }
    }

    const onConfirmDelete = async () => {
        // TODO: Very bad implementation as it requires and additional parameter
        const apiService = new ApiService(deleteEndpoint ?? url);
        try {
            setDeletionState(DeletionState.Deleting);
            await apiService.delete();
            setDeletionState(DeletionState.DeletingSuccessful);
            setTimeout(() => {
                setDeletionState(DeletionState.Deleted);
            }, 2000)
            ToastHelpers.showSimple('🚽 element gelöscht', {
                autoClose: 2000,
            });
        } catch {
            ToastHelpers.showSimple('💥 fehler beim löschen. bitte erneut versuchen.');
            setDeletionState(DeletionState.Ready);
        }
    };

    const onClickDelete = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();
        setDeletionState(DeletionState.DeletingPreparing);
        ToastHelpers.showComplex(
            <p className="text-dark text-center p-2">
                <span role="img" aria-label="see no evil">🙈</span>&nbsp;
                element wirklich löschen?
            </p>,
            <button className="btn btn-light m-2" onClick={onConfirmDelete}>
                <span role="img" aria-label="smiling imp">
                    😈
                </span>{" "}
                ja, wirklich
            </button>,
            <button className="btn btn-secondary m-2">
                <span role="img" aria-label="scream">
                    😱
                </span>{" "}
                lieber nicht
            </button>,
            () => setDeletionState(DeletionState.Ready)
        );
    };

    const getDeletionClassName = (state: DeletionState) => {
        switch (state) {
            case DeletionState.DeletingPreparing:
                return ' deleting-preparing';
            case DeletionState.Deleting:
                return ' deleting';
            case DeletionState.DeletingSuccessful:
                return ' deleting-successful';
            default:
                return '';
        }
    }

    const getRotationClassName = (state: RotationState) => {
        switch (state) {
            case RotationState.Rotating:
                return ' rotating'
            default:
                return '';
        }
    }

    // Override strictly specified types
    const title = props.title ?? image?.title;
    const subtitle = props.subtitle ?? image?.comments;
    const updated = props.updated ?? image?.updated ?? new Date();
    const pixelate = (props.rights & AccessType.Edit) <= 0 && (props.pixelate ?? true);

    // TODO: Filter actions based on user rights
    if (deletionState === DeletionState.Deleted) {
        return <></>;
    }

    const style = { "--rating": (score ?? 0) * 5 } as React.CSSProperties;
    const calculatedScore = Math.round((score ?? 0) * 100);

    return (
        <div className={className}>
            <div className={`card shadow-sm${getDeletionClassName(deletionState)}${getRotationClassName(rotationState)}`}>
                <Link to={url}>
                    <ImageLoader reloadHash={reloadHash} pixelate={pixelate} image={image} size={ImageSize.Square} text={image ? undefined : title} />
                    {(props.rights & AccessType.Edit) > 0 &&
                        <button className="btn btn-rotate" title="bild 90° im uhrzeigersinn drehen" onClick={onClickRotate}>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" style={{ height: '18px', width: '18px' }}>
                                <path fill="currentColor" d="M.62 4.9A8.07 8.07 0 014.88.64a7.78 7.78 0 016.07-.06c.95.39 1.8.93 2.55 1.63L14.85.87c.2-.22.45-.27.72-.15.28.12.42.32.42.62V6c0 .18-.07.34-.2.47a.64.64 0 01-.47.2h-4.67a.61.61 0 01-.61-.42c-.12-.27-.07-.5.14-.72L11.6 4.1a5.18 5.18 0 00-3.63-1.43A5.37 5.37 0 002.65 8a5.37 5.37 0 005.34 5.33 5.25 5.25 0 004.2-2.07c.06-.07.14-.1.25-.12.1 0 .2.03.26.1l1.43 1.43a.3.3 0 01.09.2.4.4 0 01-.07.25 7.95 7.95 0 01-9.26 2.25A8.07 8.07 0 01.62 4.9" />
                            </svg>
                        </button>
                    }
                    {props.children}
                </Link>
                <div className="card-body">
                    {(title && title.length > 0) &&
                        <h4 style={{display:'inline'}}>{title}</h4>
                    }

                    {score !== undefined &&
                        <div className="rating" style={style} title={`${calculatedScore}} % übereinstimmung - laut unserer künstlichen intelligenz. hier klicken um mehr zu erfahren.`}>{calculatedScore}&nbsp;% ähnlich</div>
                    }
                    {(subtitle && subtitle.length > 0) &&
                        <p className="card-text">{subtitle}</p>
                    }
                    <div className="d-flex justify-content-between align-items-center">
                        <div className="btn-group">
                            {(props.rights & AccessType.Edit) > 0 &&
                                <Link className="btn btn-link" to={`${url}/edit`}>bearbeiten</Link>
                            }
                            {(props.rights & AccessType.Delete) > 0 &&
                                <button disabled={deletionState !== DeletionState.Ready} className="btn btn-link" onClick={onClickDelete}>löschen</button>
                            }
                        </div>
                        <small style={{cursor: 'default'}} className="text-muted">letzte änderung <Moment locale="de-CH" fromNow date={updated} /></small>
                    </div>
                </div>
            </div>
        </div>
    );
}
