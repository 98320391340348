import React, { ReactNode } from "react";
import { Direction } from "..";

interface ISorterProps<T> {
    label: string;
    property: keyof T | "";
    direction?: Direction;
}

export abstract class SorterBase<T> extends React.Component<ISorterProps<T>> {

    public get property(): keyof T | "" {
        return this.props.property;
    }

    public get direction(): Direction {
        return this.props.direction ?? Direction.Asc;
    }

    public get label(): string {
        return this.props.label;
    }

    public get key(): string {
        return `${this.property}_${this.direction}`;
    }

    public apply(a: T, b: T): number {
        const result = this.applyInternally(a, b);
        switch (this.direction) {
            case Direction.Desc:
                return result * -1;
            default:
                return result;
        }
    }

    protected abstract applyInternally(a: T, b: T): number;

    public render(): ReactNode {
        return (
            <option value={`${this.key}`}>{this.label}</option>
        );
    }
}
