import * as React from 'react';
import { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { ValidationError, AccountModel } from '../../models';
import InlineValidationError from '../Infrastructure/InlineValidationError';
import useQuery from '../../hooks/useQuery';
import Title from '../Shared/Title';
import { AuthService } from '../../services';

export interface ILoginParams {
    redirectUrl: string | undefined;
}

export function Login() {
    const authService = new AuthService();
    const query = useQuery();
    const [name, setName] = useState<string>('');
    const [code, setCode] = useState<string>('');
    const [isSaving, setIsSaving] = useState<boolean>(false);
    const [successful, setSuccessful] = useState<boolean>(false);
    const [errors, setErrors] = useState<ValidationError | null>(null);

    const login = () => {
        setIsSaving(true);
        authService.login({ name: name, code: code } as AccountModel,
            (value: AccountModel) => {
                setSuccessful(true);
                setIsSaving(false);
            },
            (value: ValidationError) => {
                setSuccessful(false);
                setIsSaving(false);
                setErrors(value);
            });
    }

    const onLoginClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        // Do not submit this form
        event.preventDefault();
        login();
    };

    const onKeyPressed = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            login();
        }
    };

    if (successful) {

        const redirectUrl = query.get('redirectUrl');
        if (redirectUrl) {
            // ALWAYS check if it's an absolute URL or not
            const url = decodeURIComponent(redirectUrl);
            if (url.indexOf('://') <= 0 || url.indexOf('//') < 0) {
                // Absolute URL
                return <Redirect to={url} />;
            }
        }

        // redirect to home
        return <Redirect to="/" />;
    }

    return (
        <>
            <Title title="melde dich an" urlText="lieber nicht, zur startseite" url={`/`} />
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-6 mb-4">
                        <label htmlFor="name">dein name:</label>
                        <input
                            id="name"
                            className="form-control"
                            placeholder="z. B. Jonas"
                            disabled={isSaving}
                            type="text"
                            onKeyDown={onKeyPressed}
                            onChange={(e) => setName(e.target.value)}
                        />
                        <InlineValidationError field="name" error={errors} />
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-md-6 mb-4">
                        <label htmlFor="code">zugriffscode (optional):</label>
                        <input
                            id="code"
                            className="form-control"
                            placeholder="z. B. X2810"
                            disabled={isSaving}
                            type="text"
                            onKeyDown={onKeyPressed}
                            onChange={(e) => setCode(e.target.value)}
                        />
                        <InlineValidationError field="code" error={errors} />
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-md-6 mb-4">
                        <button disabled={isSaving} className="btn btn-primary btn-block" onClick={onLoginClick}>anmelden</button>
                    </div>
                </div>
            </div>
        </>
    );
}
