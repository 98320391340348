import * as React from 'react';
import { useState, useEffect } from 'react';
import useDebounce from '../../hooks/useDebounce';
import { EditStatus } from './EditStatus';
import { EditStatusWidget } from './EditStatusWidget';
import { FieldEditorType } from './FieldEditorType';
import { ValidationError } from '../../models';
import InlineValidationError from '../Infrastructure/InlineValidationError';
import { ApiService, JSONPatchOperation, JSONPatchOperationType } from '../../services';

interface IFieldEditorProps {
    value: string;
    id: string;
    label: string;
    showLabel?: boolean;
    propertyName: string;
    endpoint: string;
    type: FieldEditorType;
}

export default function FieldEditor(props: IFieldEditorProps) {
    const [value, setValue] = useState<string>(props.value);
    const [lastValue, setLastValue] = useState<string>(props.value);
    const [editState, setEditState] = useState<EditStatus>(EditStatus.Idle);
    const [error, setError] = useState<ValidationError | null>(null);
    const debouncedValue = useDebounce(value, 500);
    const apiService = new ApiService(props.endpoint);

    useEffect(() => {
        async function saveValue() {
            // if they haven't picked an image yet, we can't update its title or comments (need an id to PATCH)
            if (props.id === '') {
                return;
            }
            // Make sure we have a value (user has entered something in input)
            if (debouncedValue !== lastValue) {
                setLastValue(debouncedValue);

                // Dynamically create the JSON patch object based on the field changed
                let requestObject: Array<JSONPatchOperation> = [{
                    "op": JSONPatchOperationType.Replace,
                    "path": props.propertyName,
                    "value": debouncedValue
                }];

                await apiService.patch(
                    requestObject,
                    () => {
                        setEditState(EditStatus.Saved);
                        setTimeout(() => setEditState(EditStatus.Idle), 1500)
                    },
                    (error) => {
                        setError(error);
                        setEditState(EditStatus.Error);
                    }
                );
            }
        }
        saveValue();
    });

    // Unique key for identifying this field
    const fieldKey = `key_${props.id}_${props.propertyName}`;
    const cssClass = `form-control ${editState === EditStatus.Error ? ' is-invalid' : ''}`;

    return (
        <div className="form-group">
            {(props.showLabel || props.showLabel === undefined) &&
                <label className="text-muted" htmlFor={fieldKey}>{props.label}</label>
            }
            {props.type === FieldEditorType.Input && (
                <>
                    <input
                        type="text"
                        id={fieldKey}
                        placeholder={props.label}
                        name={fieldKey}
                        className={cssClass}
                        value={value ?? ""}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => { setEditState(EditStatus.Saving); setValue(e.target.value) }}
                    />
                    <InlineValidationError field={props.propertyName} error={error} />
                </>
            )}
            {props.type === FieldEditorType.Textarea && (
                <>
                    <textarea
                        id={fieldKey}
                        placeholder={props.label}
                        name={fieldKey}
                        className={cssClass}
                        value={value ?? ""}
                        onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => { setEditState(EditStatus.Saving); setValue(e.target.value) }}
                    />
                    <InlineValidationError field={props.propertyName} error={error} />
                </>
            )}
            <EditStatusWidget status={editState} />
        </div>
    );
}
