import * as React from 'react';
import { ColorHelper } from '../../helpers/ColorHelper';
import { ImageModel, ImageSize } from '../../models';

export interface IImageLoaderProps {
    text?: string;
    size: ImageSize;
    image?: ImageModel;
    pixelate?: boolean;
    reloadHash?: string;
}

export function ImageLoader(props: IImageLoaderProps) {
    const { size, image, text, reloadHash } = props;
    const [imageLoaded, setImageLoaded] = React.useState<boolean>(false);
    const colorInfo = ColorHelper.getCSSColorPair(0.5, text);
    const pixelate = props.pixelate ?? false;

    // Ha, nasty animation on rotate
    React.useEffect(() => {
        setImageLoaded(false);
    }, [props.reloadHash]);

    const getSrc = () => {
        let src = image?.sources[size] + (pixelate ? '&pixelate=12' : '');
        if (reloadHash) {
            src = src.replace(/@[a-f0-9]+\./g, `@${reloadHash}.`);
        }
        return src;
    }

    return (
        <>
            {(!image || !imageLoaded) && (
                // Another good way would be https://stackoverflow.com/questions/48780889/colour-overlay-fade
                <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 350 350" className="card-img-top">
                    <rect fill={colorInfo.backgroundColor} width="350" height="350" />
                    <text fill={colorInfo.color} fontFamily="sans-serif" fontSize="20" dy="10.5" fontWeight="bold" x="50%" y="50%" textAnchor="middle">
                        {text ?? "lade dein bild ..."}
                    </text>
                </svg>
            )}
            {image && (
                <img
                    style={{
                        visibility: imageLoaded ? 'visible' : 'hidden',
                        position: imageLoaded ? 'inherit' : 'absolute',
                        transition: 'all 500ms',
                        opacity: imageLoaded ? 1 : 0,
                        width: '100%',
                    }}
                    className="img-responsive card-img-top"
                    src={getSrc()}
                    alt={image.title}
                    title={image.title}
                    onLoad={() => setImageLoaded(true)}
                />
            )}
        </>
    );
}
