import * as React from 'react';
import { ErrorHelpers } from '../../helpers/ErrorHelpers';
import { ValidationError } from '../../models';

interface IInlineErrorProps {
    field: string;
    error: ValidationError | null;
}

export default function InlineValidationError(props: IInlineErrorProps) {

    const { error, field } = props;
    if (!error) {
        return <></>
    }

    const fieldErrors = ErrorHelpers.getFieldErrors(error, field);

    if (fieldErrors.length <= 0) {
        return <></>;
    }

    return (
        <div className="alert alert-danger mt-2" role="alert">
            {fieldErrors.map((error: string, index: number) => <p key={`${field}_${index}`} style={{ marginBottom: 0 }}>{error}</p>)}
        </div>
    );
}
