import * as React from "react";
import { ImageType, ImagePreviewModel } from "../../models";
import { SortableImages } from "../../services/new-sorting/SortableImages";
import Loader from "../Infrastructure/Loader";
import Preview from "../Shared/Preview";

interface IUserImagesProps {
    isLoading: boolean;
    images: ImagePreviewModel[];
    collectionId: string;
    imitationId: string;
}

export default function DetailUploads(props: IUserImagesProps) {
    const { isLoading, images, collectionId, imitationId } = props;
    const imitationImages = images.filter((x) => x.type === ImageType.ImitationRaw);

    return (
        <>
            <div className="col-lg-12 pt-4">
                <h5 className="text-center">imitationen von anderen</h5>
                <hr />
            </div>
            {imitationImages.length <= 0 && isLoading && <Loader/>}
            {imitationImages.length <= 0 && !isLoading && (
                <div className="row pb-4">
                    <div className="col-lg-12 pt-4">
                        <p className="text-center">
                            zu diesem bild gibt es noch keine imitationen. lade jetzt dein foto hoch.
                        </p>
                    </div>
                </div>
            )}
            {imitationImages.length > 0 && !isLoading && (
                <SortableImages dataSource={images}>
                    {(x: ImagePreviewModel) => {
                        const creator = `von ${x.uploadedBy?.name}`;
                        return (
                            <Preview
                                key={`ip_${x.id}`}
                                className="col-md-4 col-xs-12 p-2 specialTest"
                                image={x}
                                imitationId={imitationId}
                                title={creator}
                                score={x.score}
                                updated={x.updated}
                                rights={x.rights}
                                pixelate={true}
                                url={`/collection/${collectionId}/imitation/${imitationId}/image/${x.id}`}
                                deleteEndpoint={`/${imitationId}/image/${x.id}`}
                            >
                                <h4 className="img-info">
                                    <span role="img" aria-label={creator}>
                                        🐵
                                    </span>
                                </h4>
                            </Preview>
                        );
                    }}
                </SortableImages>


            )}
        </>
    );
}
