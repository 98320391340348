import * as React from 'react';
import FileUploader from '../Image/FileUploader';
import { useParams } from 'react-router-dom';
import IRouterParams from '../Router/IRouterParams';
import Title from '../Shared/Title';

export default function Add() {
    const { collectionId } = useParams<IRouterParams>();

    return (
        <>
            <Title title="bilder hinzufügen" urlText="album anzeigen" url={`/collection/${collectionId}`} />
            <div className="container">
                <div className="row pb-4">
                    <div className="col-md-12">
                        <FileUploader collectionId={collectionId} />
                    </div>
                </div>
            </div>
        </>
    );
}
