import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Footer from "./Layout/Footer";
import Navigation from "./Layout/Navigation";
import { AppRouter } from "./Router/AppRouter";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Constants from "../constants/Constants";
import Presentation from "./Presentation/Presentation";

export default function App() {
    return (
        <Router>
            <Switch>
                <Route
                    exact
                    path={`/collection/:collectionId(${Constants.GuidRegex})/presentation`}
                >
                    <Presentation />
                </Route>
                <Route>
                    <header>
                        <Navigation />
                    </header>
                    <main role="main">
                        <AppRouter />
                    </main>
                    <Footer />
                    <ToastContainer/>
                </Route>
            </Switch>
        </Router>
    );
}
