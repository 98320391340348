import * as React from "react";
import { useEffect, useState } from "react";
import { CollectionPreviewModel } from "../../models";
import { Link } from "react-router-dom";
import Loader from "../Infrastructure/Loader";
import Preview from "../Shared/Preview";
import Title from "../Shared/Title";
import { ApiService } from "../../services";
import { ToastHelpers } from "../../helpers/ToastHelpers";
import useWindowScrollPosition from "../../hooks/useWindowScrollPosition";

export default function Overview() {
    const [collections, setCollections] = useState<CollectionPreviewModel[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    useWindowScrollPosition('Collection_Overview_ScrollY', !isLoading);

    const fetchData = async () => {
        try {
            const apiService = new ApiService(`/collection`);
            const collections = await apiService.get<CollectionPreviewModel[]>();
            setCollections(collections);
        } catch (error) {
            ToastHelpers.showSimple('⌛ alben können nicht geladen werden. versuchen sie es in einer minute nochmals.');
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (isLoading) {
            fetchData();
        }
    });

    if (isLoading) {
        return <Loader />;
    }

    if (collections.length <= 0) {
        return <Title title="kein album gefunden" urlText="jetzt ein album erstellen" url={`/create`} />;
    }

    return (
        <>
            <Title title="wähle ein album" urlText="oder erstelle selber eines" url={`/create`}/>
            <div className="container">
                <div className="row">
                    {collections.map((x) =>
                        <Preview key={`op_${x.id}`} className="col-sm-12 col-md-4 p-2" subtitle={x.description} {...x} url={`/collection/${x.id}`}>
                            <img src="/overlay.svg" alt="imitate" className="card-img-hover" />
                        </Preview>
                    )}
                </div>

                <div className="row justify-content-center">
                    <div className="alert alert-secondary text-center">
                        jetzt ein neues <Link to={`/create`}>album erstellen</Link>.
                    </div>
                </div>
            </div>
        </>
    );
}
