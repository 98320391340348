import { ImageType } from "../models";
import IUploadedImageFile from "../models/IUploadedImageFile";
import { UploadState } from "../models/UploadState";
import { ValidationError } from "../models/ValidationError";

export default class UploadedImageFile implements IUploadedImageFile {
    public browserPreviewUrl: string;
    public imageId?: string;
    public imitationId: string;
    public state?: UploadState;
    public error?: ValidationError;
    public onImageChange?(): void;
    public originalFile: File;
    public type: ImageType;

    constructor(imitationId: string, file: File, type: ImageType) {
        this.imitationId = imitationId;
        this.originalFile = file;
        this.type = type;
        this.browserPreviewUrl = URL.createObjectURL(file);
        this.state = UploadState.Added;
    }

    public updateAttributes(imageId: string): UploadedImageFile {
        this.imageId = imageId;
        this.state = UploadState.Uploaded;
        return this;
    }

    public getAsFormData() {
        const formData = new FormData();
        formData.append('Image', this.originalFile as Blob);
        formData.append('Type', this.type.toString());
        return formData;
    }
}
