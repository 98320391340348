import * as React from "react";
import { useEffect } from "react";
import Reveal from "reveal.js";

// TODO: programmatically always copy these from npm_modules?
import "../../styles/reveal.js/reveal.css";
import "../../styles/reveal.js/theme/white.css";
import { Slide } from "./Slide";

export default function Presentation() {
    useEffect(() => {
        let deck = new Reveal({
            autoSlide: 3000,
            loop: true,
        });
        deck.initialize();
    }, []);

    return (
        <div className="reveal">
            <div className="slides">
                <Slide title="Wow, a first slide" text="here's the text prop" />
                <Slide
                    title="Second Slide!!!"
                    text="much coolness, many amazing"
                />
                <Slide
                    title="Wow, even with components"
                    text="this is truly amazing"
                />
            </div>
        </div>
    );
}
