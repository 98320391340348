import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { ImageModel, ValidationError, ImitationModel, ImageType, ImitationPreviewModel } from '../../models';
import InlineValidationError from '../Infrastructure/InlineValidationError';
import EditInline from './EditInline';
import Loader from '../Infrastructure/Loader';
import { ApiService } from '../../services';
import { ToastHelpers } from '../../helpers/ToastHelpers';
import { ErrorHelpers } from '../../helpers/ErrorHelpers';
import UploadedImageFile from '../../services/UploadedImageFile';

interface IFileUploaderProps {
    collectionId: string;
    imitationId?: string;
    imitations?: Array<ImitationPreviewModel>;
    onImageChange?(): void;
}

export default function FileUploader(props: IFileUploaderProps) {
    const { collectionId, imitations, onImageChange } = props;
    const [files, setFiles] = useState<Array<UploadedImageFile>>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [
        validationError,
        setValidationError,
    ] = useState<ValidationError | null>(null);

    const imitationService = new ApiService(`${collectionId}/imitation`);

    const createImitation = (file: File) => {
        imitationService.post<ImitationModel>({ title: file.name, },
            (value: ImitationModel) => {
                setValidationError(null);
                uploadImage(new UploadedImageFile(value.id, file, ImageType.Source));
            },
            (error) => {
                setValidationError(error);
                setIsLoading(false);
            }
        );
    }

    const uploadImage = async (file: UploadedImageFile) => {

        const imageService = new ApiService(`${file.imitationId}/image`);
        setFiles([...files, file]);

        await imageService.postFormData<ImageModel>(
            file.getAsFormData(),
            (value: ImageModel) => {
                setFiles([...files, file.updateAttributes(value.id)]);
                if (onImageChange) {
                    onImageChange();
                }
            },
            (error: ValidationError) => {
                // filter out failed uploads using browserPreviewUrl as key
                setFiles([
                    ...files.filter(x => x.browserPreviewUrl !== file.browserPreviewUrl)
                ]);
                const fieldErrors = ErrorHelpers.getFieldErrors(error, "Image");
                ToastHelpers.showSimple(`❌ ${fieldErrors.join(", ")}`);
            }
        );

        setIsLoading(false);
    };

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        multiple: true,
        accept: 'image/*',
        onDrop: (acceptedFiles) => {
            acceptedFiles.map(async (file) => {
                setIsLoading(true);
                ToastHelpers.showSimple(`🚀 bild ${file.name} hochladen ...`)
                if (props.imitationId === undefined) {
                    createImitation(file);
                } else {
                    uploadImage(new UploadedImageFile(props.imitationId, file, ImageType.ImitationRaw));
                }
            });
        },
    });

    const classNames = 'dropzone card text-center upload-card' + (files.length > 0 ? ' h-25' : '');
    const totalImages = files.length + (imitations !== undefined ? imitations.length : 0);

    return (
        <div className="h-100">
            <div className={isDragActive ? classNames + ' dragActive' : classNames} {...getRootProps()}>
                <input {...getInputProps()} />
                <div>
                    <h3>füge dein bild hinzu</h3>
                    <p>
                        {isDragActive ? 'fast geschafft 🐒' : ''}
                        {totalImages > 0 ? 'noch eins? klick nochmals!' : 'hier klicken oder reinziehen'}
                    </p>
                </div>
            </div>
            <div className="h-75">
                {isLoading && <Loader />}
                {files.length > 0 && files.map((file, i) => {
                    return (
                        <div key={file.browserPreviewUrl}>
                            <EditInline {...file} onImageChange={onImageChange} />
                            {(i < files.length - 1) && <hr className="p-0 m-0" />}
                        </div>
                    );
                })}
                <InlineValidationError field="Image" error={validationError} />
            </div>
        </div>
    );
}
