import * as React from 'react';
import { ValidationError, CollectionModel } from '../../models';
import InlineValidationError from '../Infrastructure/InlineValidationError';
import { Redirect } from 'react-router-dom';
import { useState } from 'react';
import Title from '../Shared/Title';
import { ApiService } from '../../services';

export default function Add() {
    const apiService = new ApiService(`collection`);

    const [id, setId] = useState<string>('');
    const [title, setTitle] = useState<string>('');
    const [description, setDescription] = useState<string>('');
    const [errors, setErrors] = useState<ValidationError | null>(null);
    const [isSaving, setIsSaving] = useState<boolean>(false);

    const onCreateClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        // Do not submit this form
        event.preventDefault();
        setIsSaving(true);

        // Try saving item
        apiService.post<CollectionModel>(
            {
                title: title,
                description: description,
            },
            (item) => {
                setIsSaving(false);
                setTitle('');
                setDescription('');
                setId(item.id);
            },
            (error) => {
                setIsSaving(false);
                setErrors(errors);
            }
        );
    };

    // Only way to redirect correctly according to https://dev.to/projectescape/programmatic-navigation-in-react-3p1l
    if (id.length > 0) {
        return <Redirect to={`/collection/${id}/add`} />;
    }

    return (
        <>
            <Title title="beginne ein album" urlText="zurück zu den alben" url={`/`} />
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-6 mb-4">
                        <label htmlFor="title">titel deines albums:</label>
                        <input
                            id="title"
                            className="form-control"
                            disabled={isSaving}
                            placeholder={`z. B. Lauras Kinderfotos`}
                            type="text"
                            onChange={(e) => setTitle(e.target.value)}
                        />
                        <InlineValidationError field="title" error={errors} />
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-md-6 mb-4">
                        <label htmlFor="description">beschreibe um was es geht:</label>
                        <textarea
                            id="description"
                            className="form-control"
                            disabled={isSaving}
                            placeholder={`z. B. Laura wird 25, ich habe lustige Fotos aus ihrer Kindheit gesammelt. Ich freue mich auf eure Imitationen.`}
                            onChange={(e) => setDescription(e.target.value)}
                        />
                        <InlineValidationError field="description" error={errors} />
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-md-6 mb-4">
                        <button disabled={isSaving} className="btn btn-primary" onClick={onCreateClick}>
                            kollektion erstellen
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
}
