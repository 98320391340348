import * as React from 'react';
import { Link } from 'react-router-dom';

interface ITitleProps {
    url?: string;
    urlText?: string;
    title?: string;
    description?: string;
    nextUrl?: string;
    prevUrl?: string;
}

export default function Title(props: ITitleProps) {

    const { url, urlText, title, description, nextUrl, prevUrl } = props;

    const getNextLink = () => {
        if (!nextUrl || nextUrl.length <= 0) {
            return <></>;
        }

        return (
            <Link title="nächstes" className="next" role="button" to={nextUrl}>
                <svg viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-11.5.5a.5.5 0 0 1 0-1h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5z" />
                </svg>
            </Link>);
    }

    const getPrevLink = () => {
        if (!prevUrl || prevUrl.length <= 0) {
            return <></>;
        }

        return (
            <Link title="vorheriges" className="prev" role="button" to={prevUrl}>
                <svg viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-4.5.5a.5.5 0 0 0 0-1H5.707l2.147-2.146a.5.5 0 1 0-.708-.708l-3 3a.5.5 0 0 0 0 .708l3 3a.5.5 0 0 0 .708-.708L5.707 8.5H11.5z" />
                </svg>
            </Link>);
    }

    const hasTitle = title && title.length > 0;
    const hasDescription = description && description.length > 0;
    const hasUrl = url && urlText && url.length > 0;

    return (
        <section className="text-center">
            <div className="container">
                {getPrevLink()}
                <h3 style={{ padding: '1.5rem 0 0 0' }}>{hasTitle ? title : 'titel fehlt'}</h3>
                {hasUrl &&
                    <Link style={{ padding: hasDescription ? '0 0 0.8rem 0' : '0 0 1.5rem 0', display: 'inline-block' }} to={url ?? ''}>{urlText}</Link>
                }
                {hasDescription &&
                    <p style={{ padding: '0 0 1.5rem 0', margin: 0 }} className="lead text-muted">{description}</p>
                }
                {getNextLink()}
            </div>
        </section>
    );
}
