import * as React from 'react';

export default function Footer() {
    return (
        <footer className="pt-4">
            <div className="container">
                <div className="row">
                    <div className="col-3 d-none d-md-block">
                        from <span aria-label="switzerland" role="img">🇨🇭</span> with <span aria-label="heart" role="img">❤️</span>
                        <small className="d-block mb-3 text-muted">&copy; 2019 - 2020</small>
                    </div>
                    <div className="col-6 d-none d-md-block">
                        <h5>über uns</h5>
                        <p className="text-small text-muted">
                            nachäffen mit niveau.<br />mit <b>imitate</b> stellst du im handumdrehen fotos nach.
                        </p>
                    </div>
                    <div className="col-3 d-none d-md-block">
                        <h5>status</h5>
                        <p className="text-small text-muted">
                            <b className="text-success">ok</b>, version 2020.10.1601<br />f91cb22
                        </p>
                    </div>
                    <div className="col-12 d-block d-md-none">
                        <p className="text-small text-muted">
                            <b>imitate</b><br />
                            nachäffen mit niveau<br />version 2020.10.1601, f91cb22
                        </p>
                    </div>
                </div>
            </div>
        </footer>
    );
}


