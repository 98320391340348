import { ValidationError } from "../models";

export class ApiService {

    private readonly endpoint: string;
    private readonly headers: Headers;
    private readonly redirectUri: string | null;

    constructor(endpoint: string, redirectUri: string | null = null) {
        this.redirectUri = redirectUri;
        this.endpoint = `/api/${endpoint}`.replace("//", "/");
        this.headers = new Headers({
            Accept: "application/json",
            "Content-Type": "application/json",
        });
    }

    public async get<T>(method: string | null = null): Promise<T> {
        const uri = this.endpoint + (method === null ? '' : '/' + method);
        const response = await fetch(uri);
        return this.ensureValidResponse<T>(response);
    }

    public async postFormData<T>(data: FormData, success: (value: T) => void, failed: (value: ValidationError) => void): Promise<void> {
        const init: RequestInit = {
            method: "POST",
            body: data,
            redirect: "follow"
        };

        const response = await fetch(this.endpoint, init);
        if (response.ok) {
            var model = (await response.json()) as T;
            success(model);
        } else {
            this.ensureAuthenticated(response);
            try {
                var error = (await response.json()) as ValidationError;
                failed(error);
            } catch {
                failed(ApiService.MessageFromResponse(response));
            }
        }
    }

    public async delete(): Promise<void> {
        const init: RequestInit = {
            method: "DELETE",
            redirect: "follow",
            headers: this.headers
        };

        const response = await fetch(this.endpoint, init);
        if (!response.ok) {
            // TODO: don't we want to ensure authenticated before deleting?
            this.ensureAuthenticated(response);

            // Cool info about it https://stackoverflow.com/questions/42453683/how-to-reject-in-async-await-syntax
            throw response.status;
        }
    }

    public async patch(data: any, success: () => void, failed: (value: ValidationError) => void): Promise<void> {
        const init: RequestInit = {
            method: "PATCH",
            body: JSON.stringify(data),
            redirect: "follow",
            headers: this.headers
        };

        const response = await fetch(this.endpoint, init);
        if (response.ok) {
            success();
        } else {
            this.ensureAuthenticated(response);
            try {
                var error = (await response.json()) as ValidationError;
                failed(error);
            } catch {
                failed(ApiService.MessageFromResponse(response));
            }
        }
    }

    public async post<T>(data: any, success: (value: T) => void, failed: (value: ValidationError) => void): Promise<void> {
        const init: RequestInit = {
            method: "POST",
            body: JSON.stringify(data),
            redirect: "follow",
            headers: this.headers
        };

        const response = await fetch(this.endpoint, init);
        if (response.ok) {
            var model = (await response.json()) as T;
            success(model);
        } else {
            this.ensureAuthenticated(response);
            try {
                var error = (await response.json()) as ValidationError;
                failed(error);
            } catch {
                failed(ApiService.MessageFromResponse(response));
            }
        }
    }

    private async ensureValidResponse<T>(response: Response): Promise<T> {
        if (!response.ok) {
            this.ensureAuthenticated(response);
            throw Error(`Request to ${this.endpoint} failed.`);
        }

        return (await response.json()) as T;
    }

    private ensureAuthenticated(response: Response) {
        if ([401, 403].indexOf(response.status) !== -1) {
            if (this.redirectUri !== null) {
                window.location.href = `/login?redirectUrl=${encodeURIComponent(this.redirectUri)}`;
            } else {
                window.location.href = `/login`;
            }
        }
    }

    private static MessageFromResponse(response: Response): ValidationError {
        return { title: 'Connection error.', status: response.status, traceId: '', errors: null };
    }
}
