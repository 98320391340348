import * as React from 'react';
import { useEffect, useState } from 'react';

// TODO: This loader is a bit abstract, see https://codepen.io/jkeussen/pen/Fliwg for a good example
export default function Loader() {
    const [array, setArray] = useState<Array<string>>([
        '🙈',
        '🐵',
        '🐵',
    ]);

    const arrayRotate = (arr: any[]): any[] => {
        arr.unshift(arr.pop());
        return arr;
    }

    // important to use the clearInterval in useEffect so we don't have memory leaks - react will clear the interval for us when this unmounts
    // spreading the array is also required, otherwise react doesn't think that the monkey array has 'changed'
    useEffect(() => {
        const interval = setInterval(() => {
            setArray([...arrayRotate(array)]);
        }, 350);
        
        return () => clearInterval(interval);
    }, [array]);

    return (
        <div className="p-4 text-center">
            <h6>deine daten werden geladen ...</h6>
            <p className="text-center p-2 h1">{array.join(' ')}</p>
        </div>
    );
}
