import * as React from "react";
import { Link } from "react-router-dom";

export default function Navigation() {
    return (
        <div className="container">
            <div className={'row justify-content-center pb-3'}>
                <Link to="/" className="logo" title="zur imitate startseite">
                    <img src="/logo.svg" alt="imitate - äff sie nach" />
                    <p className={'lead text-center'}>nachäffen mit niveau</p>
                </Link>
            </div>
        </div>
    );
}
