import * as React from 'react';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ToastHelpers } from '../../helpers/ToastHelpers';
import useWindowScrollPosition from '../../hooks/useWindowScrollPosition';
import { CollectionModel } from '../../models';
import { ApiService } from '../../services';
import FieldEditor from '../Editor/FieldEditor';
import { FieldEditorType } from '../Editor/FieldEditorType';
import Loader from '../Infrastructure/Loader';
import IRouterParams from '../Router/IRouterParams';
import Title from '../Shared/Title';

export default function Edit() {
    const { collectionId } = useParams<IRouterParams>();
    const [collection, setCollection] = useState<CollectionModel | undefined>(undefined);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    useWindowScrollPosition('Collection_Edit_ScrollY', !isLoading);

    const fetchData = async () => {
        try {
            const apiService = new ApiService(`/collection/${collectionId}`);
            const collection = await apiService.get<CollectionModel>();
            setCollection(collection);
        } catch (error) {
            ToastHelpers.showSimple('⌛ album kann nicht geladen werden. bitte später nochmals versuchen.');
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (isLoading) {
            fetchData();
        }
    });

    if (isLoading) {
        return <Loader />;
    }

    if (collection === undefined) {
        return <Title title="kein album gefunden" urlText="jetzt ein album erstellen" url={`/create`} />;
    }

    return (
        <>
            <Title title="album bearbeiten" urlText="zurück zu den öffentlichen albun" url={`/collection/${collectionId}`} />
            <div className="container">
                <div className="row pb-4">
                    <div className="col-md-12">
                        <FieldEditor
                            id={collection.id}
                            value={collection.title}
                            type={FieldEditorType.Input}
                            label="titel:"
                            propertyName="Title"
                            endpoint={`/collection/${collectionId}`}
                        />
                        <FieldEditor
                            id={collection.id}
                            value={collection.description}
                            type={FieldEditorType.Textarea}
                            label="beschreibung:"
                            propertyName="Description"
                            endpoint={`/collection/${collectionId}`}
                        />
                    </div>
                </div>
            </div>
        </>
    );
}
