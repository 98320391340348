import * as React from "react";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Moment from "react-moment";
import 'moment/locale/de-ch';
import { ImageViewModel, ImageSize, AccessType } from "../../models";
import Loader from "../Infrastructure/Loader";
import IRouterParams from "../Router/IRouterParams";
import Title from "../Shared/Title";
import { ApiService } from "../../services";
import { ImageLoader } from "./ImageLoader";
import { ColorHelper } from "../../helpers/ColorHelper";

export default function Detail() {
    const { imitationId, imageId, collectionId } = useParams<IRouterParams>();
    const [image, setImage] = useState<ImageViewModel | undefined>(undefined);
    const imageService = new ApiService(`${imitationId}/image/${imageId}`);

    const fetchImage = async () => {
        const image = await imageService.get<ImageViewModel>();
        setImage(image);
    };

    useEffect(() => {
        if (image === undefined) {
            fetchImage();
        }
    });

    if (image === undefined) {
        return <Loader />;
    }

    return (
        <>
            <Title title="bilddetails anzeigen" urlText="zurück zum originalbild" url={`/collection/${collectionId}/imitation/${imitationId}`} />
            <div className="container">
                <div className="row pb-4">
                    <div className="col-md-6 mb-4 p-0">
                        <ImageLoader image={image} size={ImageSize.Square} pixelate={(image.rights & AccessType.Edit) <= 0} />
                    </div>
                    <div className="col-md-6">
                        <h3>{image.title}</h3>
                        <hr />
                        {image.score > 0 &&
                            <>
                                <p>unser system hat eine <b>ähnlichkeit von {Math.round(image.score * 100)}&nbsp;%</b> zum originalbild berechnet.</p>
                                <p>tags: {image.tags.map((value, index) => {
                                    const colorInfo = ColorHelper.getCSSColorPair(1, value);
                                    return (
                                        <span style={colorInfo} className="badge p-2 m-1 tag" key={`tag_${index}`}>
                                            {value}
                                        </span>
                                    )
                                })}</p>
                                <hr />
                            </>
                        }
                        {image.comments &&
                            <>
                                <p>{image.comments}</p>
                                <hr />
                            </>
                        }
                        <p>von: {image.uploadedBy?.name}</p>
                        {image.updated &&
                            <p>geändert: <Moment locale="de-CH" calendar date={image.updated} /></p>
                        }
                    </div>
                </div>
            </div>
        </>
    );
}
