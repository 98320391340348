export enum AccessType {

    /// <summary>
    /// Cannot view this item at all.
    /// </summary>
    None = 0,

    /// <summary>
    /// Is able to see this item.
    /// </summary>
    View = 1,

    /// <summary>
    /// Can edit this item.
    /// </summary>
    Edit = 2,

    /// <summary>
    /// Is allowed to remove this item.
    /// </summary>
    Delete = 4,
}