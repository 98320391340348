import * as React from "react";

export interface ISlideProps {
    title: string;
    text: string;
}

export function Slide(props: ISlideProps) {
    const { title, text } = props;
    return (
        <section data-transition="slide">
            <h2>{title}</h2>
            <p>
                {text}
            </p>
        </section>
    );
}
