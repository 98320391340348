import { CSSProperties } from "react";

export abstract class ColorHelper {

    // Returns black or white - whatever gives the better contrast
    private static getYiqContrastColor = (color: number[]): number[] => {
        // Get YIQ ratio, stolen from https://24ways.org/2010/calculating-color-contrast/
        var yiq = (color[0] * 299 + color[1] * 587 + color[2] * 114) / 1000;
        return yiq >= 128 ? [0, 0, 0] : [255, 255, 255];
    };

    // Returns a random color
    private static getRandomColor = (text?: string): number[] => {
        // 16777216 = 2^25 to generate a random color
        let hash = Math.floor(Math.random() * 16777216);

        // Function to calculate a hash on the text ... so the color matches the text description
        if (text) {
            hash = 0;
            for (let i = 0; i < text.length; i++) {
                hash = (hash << 5) - hash + text.charCodeAt(i);
                hash |= 0;
            }
        }

        // Generate the hex code from the newly created color, then add the alpha channel
        return [0, 1, 2].map((x) => (hash >> (x * 8)) & 255);
    };

    // Converts number array to rgba value
    private static toRgbaColor = (color: number[], alpha: number = 1): string => {
        const output = new Array<number>(...color);
        output.push(alpha);

        // Create the rgba string
        return `rgba(${output.join(",")})`;
    };

    public static getCSSColorPair(alpha: number, text?: string): CSSProperties {
        const backgroundColorHex = ColorHelper.getRandomColor(text);
        return {
            color: ColorHelper.toRgbaColor(ColorHelper.getYiqContrastColor(backgroundColorHex)),
            backgroundColor: ColorHelper.toRgbaColor(backgroundColorHex, alpha),
        };
    }
}
