import { ApiService } from ".";
import { AccountModel, ValidationError } from "../models";

export class AuthService {

    public readonly apiService: ApiService;

    constructor() {
        this.apiService = new ApiService("account");
    }

    public async getUser(): Promise<AccountModel | null> {
        try {
            return await this.apiService.get<AccountModel>();
        } catch {
            return null;
        }
    }

    public async login(model: AccountModel, success: (value: AccountModel) => void, failed: (value: ValidationError) => void): Promise<void> {
        await this.apiService.post<AccountModel>(model,
            (value: AccountModel) => {
                success(value);
            },
            (value: ValidationError) => {
                failed(value);
            });
    }

    public async logout(): Promise<void> {
        await this.apiService.get('logout');
    }
}