import { ImagePreviewModel } from "../../models";
import { Direction } from "../sorting";
import { SortableBase, SortableBaseDefaultProps } from "./SortableBase";

export class SortableImages extends SortableBase<ImagePreviewModel> {
    static defaultProps: SortableBaseDefaultProps<ImagePreviewModel> = {
        sorters: [
            { label: "🙉 beste übereinstimmung", property: "score", direction: Direction.Desc },
            { label: "🙈 wenig ähnlich", property: "score", direction: Direction.Asc },
            { label: "🧓 älteste", property: "updated", direction: Direction.Asc },
            { label: "👶 neuste", property: "updated", direction: Direction.Desc }
        ],
    }
}
