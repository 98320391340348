import * as React from 'react';
import { Link } from 'react-router-dom';

export default function NotFound() {
  return (
    <div className="container">
      <div className="row justify-content-center">
        <img className="pt-4" src="/hey.svg" alt="was machst du?" width="40%" />
        <p className="text-center p-3 m-0">was du suchst, finden wir nicht. <br/><Link to={``}>hier</Link> geht`s zur startseite.</p>
      </div>
    </div>
  );
}
