export enum ImageSize {

    // Side length = 200
    Thumbnail = 'Thumbnail',

    // Side length = 350
    Square = 'Square',

    // Side length = 750
    Full = 'Full',
}